<template>
  <div class="shadow-title">
    <h2 class="shadow-title__en">{{en}}</h2>
    <h2 class="shadow-title__cn">{{cn}}</h2>
  </div>
</template>

<script>
  export default {
    name: "ShadowTitle",
    props: {
      cn: String,
      en: String,
    }
  }
</script>

<style lang="scss" scoped>
.shadow-title {
  &__en {
    color: #dcdcdc;
    text-indent: 1.4em;
    font-size: 40px;
  }
  &__cn {
    color: #333333;
    margin-top: -0.6em;
    font-size: 40px;
  }
}

</style>
