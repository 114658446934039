export default [
  {
    "href": "http://mp.weixin.qq.com/s?__biz=MzI0ODQ1NzgwOA==&mid=2247495685&idx=1&sn=d14b58395057a96f23c261f05c454d29&chksm=e9a2cf3aded5462c980c46efb2b007b43540b95d4846aa95afbe67ba876d23ae173932aea2f7#rd",
    "title": "【乐享置业】泰国的医疗好在哪里？",
    "abstract": "泰国作为旅游大国，又是个旅游医疗的强国！，医疗与旅游带给泰国，每年将近1200亿泰铢的收入，并且常年保持两位数增长，这篇文章将为您，深度解析下泰国的医疗水平。",
    cover: require('../img/news/20200528.jpg'),
    "time": "2020-05-28"
  },
  {
    "href": "http://mp.weixin.qq.com/s?__biz=MzI0ODQ1NzgwOA==&mid=2247495608&idx=1&sn=0b8e882dde30ce47cdefac7b1fe4ef6a&chksm=e9a2d087ded55991c338fb3e3f156ba019cd958daeddf5aba3b010ddd1a71cb1d2cb0cdffa86#rd",
    "title": "【乐享置业】疫情后泰国房产或许将更加吸引海外投资者加入！",
    "abstract": "2019年赴泰国旅游的国际游客人数已从2018年额3800万人次提升到3900万人次，创下历史新高。而中国游客在2019年旅游人数达到1100万至1200万人次，也是连续多年排名泰国的游客国家第一名。",
    cover: require('../img/news/20200521.jpg'),
    "time": "2020-05-21"
  },
  {
    "href": "http://mp.weixin.qq.com/s?__biz=MzI0ODQ1NzgwOA==&mid=2247495548&idx=1&sn=0df86eae27895d02087ee296af1b3588&chksm=e9a2d043ded559556d8de186d69714a8630ee5623c6bc14c243c68e657722efe23f80640cc02#rd",
    "title": "【乐享置业】5月到来，泰国疫情即将要烟消云散！",
    "abstract": "2020年来到了第五个月了，对于这些在泰国的同胞们来说，过得都有点心惊肉跳，还好，5月初，终究过去了，疫情也缓和了，快要烟消云散了！自四月下旬起始至今，更是连续10天回落至个位数，泰国新增有望清零",
    cover: require('../img/news/20200514.jpg'),
    "time": "2020-05-14"
  },
  {
    "href": "http://mp.weixin.qq.com/s?__biz=MzI0ODQ1NzgwOA==&mid=2247495390&idx=1&sn=c7af00daee387e8371a118e40854ccce&chksm=e9a2d1e1ded558f745ba42d979e0886ed42970be71c111b53d8e66a49e53359f82bb1b141185#rd",
    "title": "【乐享动态】疫情加股市的双重压力下，海外置业的投资热度丝毫不减？",
    "abstract": "每个人都在被大环境裹挟着难以独善其身，关键时候，如何有效规避风险，保障资产不缩水，我们需要好好考虑。在此大环境下，如何精明地投资，有什么投资经验和技巧借鉴，该如何检查并调整自己的资产配置？",
    cover: require('../img/news/20200507.jpg'),
    "time": "2020-05-07"
  },
  {
    "href": "http://mp.weixin.qq.com/s?__biz=MzI0ODQ1NzgwOA==&mid=2247495372&idx=1&sn=652e51b79da17a9a610e14c0e87fbe0c&chksm=e9a2d1f3ded558e5b040afe4c67105b300928f2f26a4b1efb407a2c69226c2944cf9274cf4d9#rd",
    "title": "【乐享动态】劳动节，致敬每一个努力生活的人！",
    "abstract": "劳动创造人类，劳动创造世界，劳动更创造未来。劳动者伟大，劳动者光荣，劳动者最值得歌颂。在这劳动者的节日里，向天下所有劳动者——致敬！",
    cover: require('../img/news/20200430.jpg'),
    "time": "2020-04-30"
  },
]
