<template>
  <section class="business-view">
    <img src="../../../assets/img/title_group@2x.png" alt="集团业务" class="title">
<!--模块1-->
    <div class="block__container">
      <div class="block block--1">
        <div class="block__text" ref="text1">
          <shadow-title en="Brand Activities" cn="品牌活动"/>
          <p>丰富的线下品牌活动</p>
          <p>实地考察项目</p>
          <p>与上百位业内人士交流讨论</p>

          <p>— 320乐享理财日</p>
          <p>— 香港马会</p>
          <p>— 乐享置业</p>
        </div>
        <div class="three-circle">
          <div class="three-circle__img-container">
            <img src="../../../assets/img/circle-3.jpg" alt="">
          </div>
          <div class="three-circle__img-container">
            <img src="../../../assets/img/circle-1.jpg" alt="">
          </div>
          <div class="three-circle__img-container">
            <img src="../../../assets/img/circle-2.jpg" alt="">
          </div>
        </div>
      </div>
    </div>

<!--模块2 线上直播-->
    <div class="block__container">
      <div class="block block--2">
        <div class="three-rect">
          <div class="three-rect__img-container">
            <img src="../../../assets/img/pic_03@2x.png" alt="市场分析">
            <span>市场分析</span>
          </div>
          <div class="three-rect__img-container">
            <img src="../../../assets/img/pic_05@2x.png" alt="海外置业">
            <span>海外置业</span>
          </div>
          <div class="three-rect__img-container">
            <img src="../../../assets/img/pic_04@2x.png" alt="理财规划">
            <span>理财规划</span>
          </div>
        </div>
        <div class="block__text block__text--special" ref="text2">
          <shadow-title en="Online Live" cn="线上直播"/>
          <p>个人理财、投资规划、市场分析</p>
          <p>多年实战经验讲师线上授课</p>
          <p>— 海外置业</p>
          <p>— 理财规划</p>
          <p>— 市场分析</p>
        </div>
      </div>
    </div>
<!--模块3-->
    <div class="block__container">
      <div class="block block--3">
        <div class="block__text" ref="text3">
          <shadow-title en="Product Service" cn="产品服务"/>
          <p>提供优质财务规划服务</p>
          <p>定制专属的八大规划</p>
          <p>筛选合适的解决方案</p>
          <p>— 八大规划</p>
          <p>— LPPP</p>
          <p>— ELPPP</p>
        </div>
        <img src="../../../assets/img/pic_07@2x.png" alt="产品服务">
      </div>
    </div>
<!--模块4-->
    <div class="block__container">
      <div class="block block--4">
<!--        2020-7-28要求修改图片-->
<!--        <img src="../../../assets/img/pic_06@2x.jpg" alt="团队阵容">-->
        <img src="../../../assets/img/pic_06@2x_new.jpg" alt="团队阵容">
        <div class="block__text block__text--special" ref="text4">
          <shadow-title en="Team Squad" cn="团队阵容"/>
          <p>专业知识、专业团队、个性化服务</p>
          <p>您的终身财富管家，我的终生事业</p>
          <p>— 100多位专业FP和企服专家</p>
          <p>— 遍布全球20多个地区</p>
          <p>— 一对一服务</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ShadowTitle from "../../../components/common/ShadowTitle"
  export default {
    name: "BusinessView",
    components: {
      ShadowTitle
    },
    mounted() {
      // 文字飞入效果
      let handleScroll = () => {
        let bottom = window.innerHeight + window.pageYOffset
        if(bottom >= this.$refs.text1.offsetTop) {
          this.$refs.text1.classList.add('block__text--fly-in-from-left')
        }
        if(bottom >= this.$refs.text2.offsetTop) {
          this.$refs.text2.classList.add('block__text--fly-in-from-right')
        }
        if(bottom >= this.$refs.text3.offsetTop) {
          this.$refs.text3.classList.add('block__text--fly-in-from-left')
        }
        if(bottom >= this.$refs.text4.offsetTop) {
          this.$refs.text4.classList.add('block__text--fly-in-from-right')
          document.removeEventListener('scroll', handleScroll)
        }
      }
      document.addEventListener('scroll', handleScroll)
    }
  }
</script>

<style lang="scss" scoped>
.business-view {
  margin-top: 80px;
  @include clearfix;
  text-align: center;
  background: url("../../../assets/img/bg_13@2x.png") no-repeat;
  background-position: left 97px;
  // 防止字体从屏幕外进入引起进度条出现
  overflow: hidden;
}
.title {
  display: block;
  margin: 0 auto 70px;
}
.block__container {
  margin: 0 auto;
  .block--2 {
    margin-top: 100px;
    .block__text {
      margin-top: 85px;
    }
  }
  .block--3 {
    margin-top: 145px;
    .block__text {
      margin-top: 40px;
    }
  }
  .block--4 {
    margin-top: 160px;
    img {
      width: 650px;
      height: 433px;
    }
  }
}

// 每一个模块
.block {
  display: flex;
  justify-content: space-between;
  @include clearfix;
  img, .block__text {
    float: left;
  }
}

.block__text {
  text-align: left;
  p {
    font-size: 24px;
    color: #666666;
    &:nth-child(2) {
      margin-top: 50px;
      margin-bottom: 20px;
    }
    &:nth-child(3) {
      margin-bottom: 20px;
    }
    &:nth-child(4) {
      margin-bottom: 40px;
    }
  }
}

.block__text--special {
  p {
    &:nth-child(3) {
      margin-bottom: 40px;
    }
    &:nth-child(4) {
      margin: 0;
    }
  }
}
.three-circle {
  width: 480px;
  height: 500px;
  float: left;
  position: relative;
}
.three-circle__img-container {
  display: block;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  img {
    height: 100%;
    margin-left: -35%;
  }
  // 交互动态
  transition: all 0.5s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
  // 移动
  position: absolute;
  &:nth-child(1) {
    top: 72px;
    left: 0;
    box-shadow: -6px 6px 0 #ed7e53;
  }
  &:nth-child(2) {
    right: 0;
    top: 0;
    box-shadow: 6px -6px 0 #ffb88d;
  }
  &:nth-child(3) {
    left: 188px;
    top: 260px;
    box-shadow: 6px 6px 0 #efd59d;
  }
}

// 线上直播左侧三个长方形
.three-rect {
  .three-rect__img-container {
    display: block;
    float: left;
    margin-right: 30px;
    &:nth-child(2) {
      margin-top: 110px;
    }
    position: relative;
    span {
      position: absolute;
      width: 100px;
      bottom: 10px;
      right: 5px;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>

<style lang="scss">
// 文字飞入特效, 全局样式
@keyframes fly-left {
  0% {transform: translateX(-500%)}
  20% {transform: translateX(-500%);}
  100% {transform: translateX(0);}
}
@keyframes fly-right {
  0% {transform: translateX(500%);}
  20% {transform: translateX(500%);}
  100% {transform: translateX(0);}
}
.block__text--fly-in-from-left {
  .shadow-title {
    animation: fly-left 1s;
  }
  p {
    &:nth-child(2) {
      animation: fly-left 1.2s;
    }
    &:nth-child(3) {
      animation: fly-left 1.4s;
    }
    &:nth-child(4) {
      animation: fly-left 1.6s;
    }
    &:nth-child(5) {
      animation: fly-left 1.8s;
    }
    &:nth-child(6) {
      animation: fly-left 2s;
    }
    &:nth-child(7) {
      animation: fly-left 2.2s;
    }
  }
}
.block__text--fly-in-from-right {
  .shadow-title {
    animation: fly-right 1s;
  }
  p {
    &:nth-child(2) {
      animation: fly-right 1.2s;
    }
    &:nth-child(3) {
      animation: fly-right 1.4s;
    }
    &:nth-child(4) {
      animation: fly-right 1.6s;
    }
    &:nth-child(5) {
      animation: fly-right 1.8s;
    }
    &:nth-child(6) {
      animation: fly-right 2s;
    }
    &:nth-child(7) {
      animation: fly-right 2.2s;
    }
  }
}
</style>
