<template>
  <!--首页最新动态-->
  <section v-if="asyncNews[0]">
    <img src="../../../assets/img/title_news@2x.png" alt="最新动态" class="home-news__title">
    <div class="home-news__container">
      <!--左侧新闻和轮播图-->
      <div class="home-news__left-container">
        <div class="home-news__banner">
          <swiper :options="swiperOption" ref="mySwiper" style="width: 100%; height: 100%;">
            <swiper-slide v-for="img in asyncNews" :key="img.title">
              <a :href="img.link" target="_blank" ref="imgHolder"></a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <a class="home-news__text" :href="asyncNews[0].link" style="display: block">
          <h2 :title="asyncNews[0].title">{{asyncNews[0].title}}</h2>
          <p>{{asyncNews[0].depict}}</p>
        </a>
      </div>

      <!--右侧4个新闻-->
      <div class="home-news__right-container">
        <div class="home-news__text-item" v-for="(item, index) in asyncNews.slice(1, 5)" :key="item.title">
          <a :href="item.link" target="_blank">
            <div class="home-news__text-index">
              <img src="../../../assets/img/pic_02@2x.png" alt="">
              <span>{{index + 1}}</span>
            </div>
            <div class="home-news__text">
              <h2 :title="item.title" :class="{'title--special': /^【/.test(item.title)}">{{item.title}}</h2>
              <p>{{item.depict}}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import news from '../../../assets/js/news'

  import 'swiper/css/swiper.css'
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
  export default {
    name: "NewsView",
    components: {
      SwiperSlide,
      Swiper
    },
    directive: {
      swiper: directive
    },
    data() {
      return {
        news: news,
        asyncNews: [],
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          // swiper的实现方式限制, 用backgroundImage来展示图片的话循环会出现空白图片
          // loop: true,
          autoplay: true
        },
      }
    },
    created() {
      this.$axios.news.getNews({
        sort: 'relTime',
        types: 'lxwx',
        page: 0,
        limit: 5
      }).then(res => {
        this.asyncNews = res.data.list
        console.log(res.data.list)
        this.$nextTick().then(() => {
          let images = this.$refs.imgHolder
          // banner自适应长宽
          for(let i=0; i<images.length; i++) {
            images[i].style.backgroundImage = `url("${this.asyncNews[i].image}")`
          }
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
section {
  padding-top: 80px;
  text-align: center;
}
.home-news__title {
  display: block;
  margin: 0 auto 70px;
}
.home-news__container {
  @include clearfix;
  display: flex;
  width: 1350px;
  margin: 0 auto;
}

// 左侧轮播图 + 一则新闻
.home-news__left-container {
  flex: 1;
  margin-right: 100px;
}
.home-news__banner {
  overflow: hidden;
  background: #666666;
  width: 575px;
  height: 330px;
  position: relative;
  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }
}
// 所有新闻的标题 + 摘要 样式
.home-news__text {
  width: 575px;
  text-align: left;
  h2 {
    margin: 20px 0 18px;
    font-size: 24px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  p {
    font-size: 18px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

// 右侧四个新闻
.home-news__right-container {
  flex: 1;
  .home-news__text {
    h2 {
      margin-top: 0;
    }
  }
}
.home-news__text-item {
  @include clearfix;
  margin-bottom: 28px;
  a {
    display: flex;
  }
}
// 右侧新闻的橙色数字
.home-news__text-index {
  position: relative;
  margin-right: 30px;
  img {
    height: 70px;
    width: 70px;
    display: block;
  }
  span {
    position: absolute;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    top: 16px;
    left: 23px;
    font-weight: bold;
  }
}

// 深入swiper组件
::v-deep .swiper-pagination-bullets {
  text-align: right;
  box-sizing: border-box;
  padding-right: 10px;
}
::v-deep .swiper-pagination-bullet-active {
  background: #ffffff;
}

// 兼容到1280px宽度
@media (max-width: 1600px) {
  .home-news__container {
    width: 1120px;
  }
  .home-news__banner {
    width: 460px;
    height: 264px;
  }
  .home-news__text {
    width: 460px;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}
</style>
